<template>
  <div>
    <input
      v-model='inputValue'
      :placeholder='placeholder'
      @input='checkInputCharacters($event)'
      class='border py-1 px-2 rounded block w-full'>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldText',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      inputValue: '',
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.inputValue = newVal
        }
      }, 
      immediate: true,
    },
    inputValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:inputValue', newVal)
        }
      }, 
      immediate: true
    },
  },
  computed: {
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
    blockKorean () {
      return this.metaData && this.metaData.blockKorean ? this.metaData.blockKorean : false
    },
    removeSpaces () {
      return this.metaData && this.metaData.removeSpaces ? this.metaData.removeSpaces : false
    },
  },
  methods: {
    checkInputCharacters (event) {
      if (this.removeSpaces) {
        this.inputValue = event.target.value.replace(/\s+/g, '')
      }
      if (this.blockKorean) {
        event.target.value = event.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig, '')
      }
    },
  }
}
</script>